export default {
    colors: {
        primary: '#0059AD',
        background: '#FFFFFF',
        shape: `#0059AD11`,
        title: `#3D3D4D`,
        text: `#6C6C80`,
        components: {
            blockquote: {
                background: `#feebc8`,
                text: `#2d3748`,
            },
        },
    },
};