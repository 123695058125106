// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/homepage-query.js" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

